<template>
    <div class="StaffManage-list">
        <GlobalInfoBar title="红包账单" content="统计红包入账/出账的交易订单" />
        <GlobalChunk icon="search" title="筛选查询">
            <div slot="tip" v-if="isshowBalance"
                style="margin-left:5px;font-size: 18px;color: #333;font-weight: bold;display: flex;align-items: center;">
                （当前商户红包账户余额：{{ redbageBalance || 0 }}元）<img src="@/assets/images/refresh.png" alt=""
                    style="width: 36px;height: 36px;" @click="handlebalance" class="rotate-image"></div>
            <div slot="filter">
                <redbageForm @showBalance="showBalance" ref="Form" />
            </div>
            <redbageTable />
        </GlobalChunk>
    </div>
</template>

<script>
export default {
    components: {
        redbageForm: () => import("./form"),
        redbageTable: () => import("./table"),
    },
    data() {
        return {
            redbageBalance: 0,
            isshowBalance: false
        };
    },
    created() { },
    mounted() {
        
    },
    methods: {
        // 查询余额
        handlebalance() {
            console.log(112222)
            document.querySelector('.rotate-image')?.classList.toggle('rotate');
            let SeachParams = this.$store.state.tagsView.SeachParams
            this.$refs.Form.getCompanyAccount()
        },
        showBalance(num,bolean) {
            this.isshowBalance = bolean
            this.redbageBalance=num
        }
    },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.StaffManage-list {
    .button_top {
        cursor: pointer;
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .faultBtn {
        width: 280px;
        height: 42px;
        background: #f9fbfd;
        border: 1px solid #c1d1ff;
        box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
        border-radius: 21px;
        display: flex;

        .left {
            text-align: center;
            color: white;
            width: 50%;
            height: 14px;
            font-size: 14px;
            font-family: FZLanTingHei-M-GBK;
            font-weight: 400;
            line-height: 40px;
            height: 40px;
            background: #0981ff;
            border-radius: 20px;
        }

        .right {
            text-align: center;
            color: #333;
            width: 50%;
            height: 14px;
            font-size: 14px;
            font-family: FZLanTingHei-M-GBK;
            font-weight: 400;
            line-height: 40px;
            height: 40px;
            border-radius: 20px;
        }
    }
}
.rotate-image {
  width: 36px;
  height: 36px;
  transition: transform 0.5s;
}

.rotate-image.rotate {
  animation: rotate360  1s linear;
}

@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>